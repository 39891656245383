<template>
  <div class="permissions-page">
    <!-- 页头 返回 -->
    <el-page-header
      content="应用权限"
      @back="goBack"
    />
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="permissions-content">
          <div style="padding:15px">
            <div style="margin-bottom:10px">
              应用权限
            </div>
            <div style="border:1px solid #ccc;height:calc(100vh - 265px)">
              <div
                v-for="(item, index) in applicationList"
                :key="index"
                :style="{
                  backgroundColor: item.id == selectApp.id ? '#f2f2f2' : ''
                }"
                class="application"
              >
                <div
                  class="clickItem"
                  @click="selectItem(item)"
                >
                  <el-checkbox
                    v-model="item.selected"
                    :true-label="1"
                    :false-label="0"
                    @click.stop.native="() => {}"
                    @change="selectApplication(item, item.selected, $event)"
                  />
                  <font
                    :style="{
                      color: item.selected == 1 ? '#409EFF' : '',
                      'margin-left': '10px'
                    }"
                  >
                    {{ item.name }}
                  </font>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <div
          class="permissions-content"
          style="position: relative;"
        >
          <el-tabs
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="(item, index) in ['页面权限', '菜单权限', '功能权限']"
              :key="index"
              :disabled="rightClick"
              :label="item"
              :name="index | changeNum"
            />
            <!--  -->
            <div
              v-if="activeName == 0"
              class="content"
              style="height: calc(100vh - 272px)"
            >
              <el-row
                v-if="!rightClick"
                :gutter="20"
                style="height:100%"
              >
                <el-col
                  :span="10"
                  style="height:100%"
                >
                  <div style="font-size:13px;margin:5px 0px">
                    页面
                  </div>
                  <div style="border:1px solid #ccc;height:calc(100vh - 300px)">
                    <div
                      v-if="pageList.length > 0"
                      style="height:100%;overflow-y:auto"
                    >
                      <div
                        v-for="(item, index) in pageList"
                        :key="index"
                        :style="{
                          backgroundColor:
                            item.id == selectPageItem.id ? '#f2f2f2' : ''
                        }"
                        class="application"
                      >
                        <div
                          class="clickItem"
                          @click="pageItem(item)"
                        >
                          <el-checkbox
                            v-model="item.selected"
                            :true-label="1"
                            :false-label="0"
                            @click.stop.native="() => {}"
                            @change="selectPage(item)"
                          />
                          <font
                            :style="{
                              color: item.selected == 1 ? '#409EFF' : '',
                              'margin-left': '10px'
                            }"
                          >
                            {{ item.name }}
                          </font>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="no-data"
                    >
                      暂无数据
                    </div>
                  </div>
                </el-col>
                <el-col
                  :span="14"
                  style="height:100%"
                >
                  <div style="font-size:13px;margin:5px 0px">
                    元素
                  </div>
                  <div
                    style="border:1px solid #ccc;height:calc(100vh - 300px);padding-top:10px;padding-bottom:10px;overflow-y:auto"
                  >
                    <!-- 树控 -->
                    <el-tree
                      ref="elementTree"
                      style="height: 100%;"
                      :data="elementList"
                      :check-strictly="true"
                      show-checkbox
                      node-key="id"
                      :default-expand-all="true"
                      :props="defaultProps"
                      @check-change="handleElementCheck"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
            <div
              v-if="activeName == 1"
              class="content"
            >
              <!-- 菜单权限 -->
              <div
                style="height:100%;border:1px solid #ccc;padding:10px;overflow-y: auto;"
              >
                <el-tree
                  ref="menusTree"
                  style="height: 100%;"
                  :data="menuList"
                  :check-strictly="true"
                  show-checkbox
                  node-key="id"
                  :default-expand-all="true"
                  :props="defaultProps"
                  @check="handleMenusCheck"
                />
              </div>
            </div>
            <div
              v-if="activeName == 2"
              class="content"
            >
              <!-- 功能权限 -->
              <div style="height:100%;border:1px solid #ccc;overflow-y:auto">
                <div v-if="functionList.length > 0">
                  <div
                    v-for="(item, index) in functionList"
                    :key="index"
                    style="padding:20px"
                    class="application"
                  >
                    <el-checkbox
                      v-model="item.selected"
                      :true-label="1"
                      :false-label="0"
                      @click.stop.native="() => {}"
                      @change="selectAllFunction(item)"
                    />
                    <font
                      :style="{
                        color: item.selected == 1 ? '#409EFF' : '',
                        'margin-left': '10px'
                      }"
                    >
                      {{ item.name }}
                    </font>
                  </div>
                </div>
                <div
                  v-else
                  class="no-data"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </el-tabs>
          <div class="right-version">
            <div class="add-name">
              资源版本
            </div>
            <el-select
              v-model="version"
              style="width:100px"
              size="mini"
              placeholder="请选择"
              :disabled="rightClick"
              @change="changVersion"
            >
              <el-option
                v-for="(item, index) in versionOption"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 左边部分  一开始不显示-->

    <!-- 右边部分 点击左边才显示 -->
  </div>
</template>

<script>
// import { areas } from "../../../api/public";
import common from "@/assets/js/common";
import {
  applicationPermissionList,
  setApplicationPermission,
  groupPageList,
  setGroupList,
  groupFunction,
  pageElementPermission,
  setPageElementPermission,
  setGroupFunction,
  menusList,
  setMenus,
} from "@/api/setting/roleSetting";
import { resourceVersion } from "@/api/public.js";

export default {
  filters: {
    changeNum(val) {
      return String(val);
    },
  },
  props: {
    rolePermession: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      groupInfo: this.rolePermession,
      rightClick: true,
      applicationList: [], //应用权限列表
      selectAppArr: [], //选中应用数组
      selectApp: {}, //选择的对象
      menuList: [], //菜单权限列表
      functionList: [], //功能权限列表
      pageList: [],
      selectPageItem: {}, //点击页面某个对象
      selectedPageArr: [], //选中的数组
      elementList: [],
      selectFunctionArr: [], //选中应用数组ons
      activeName: "0",
      versionOption: [],
      version: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      check: [],
    };
  },
  watch: {
    rolePermession(val) {
      console.log(val);
      this.groupInfo = val;
    },
  },
  created() {
    this.getApplicationList();
  },
  methods: {
    goBack() {
      this.$emit("back", false);
    },
    getPageList() {
      let data = {
        roleId: this.groupInfo.id,
        applicationId: this.selectApp.id,
        version: this.version,
      };
      groupPageList(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.pageList = res.data.list;
        }
      });
    },
    // 选择一条应用显示右边的数据
    selectItem(item) {
      this.selectApp = item;
      this.rightClick = false;
      this.$nextTick(() => {
        this.getVersion(item);
        this.elementList = [];
        this.selectPageItem = {};
      });
    },
    // 多选框选中的额操作
    selectApplication(item) {
      setApplicationPermission({
        roleId: this.groupInfo.id,
        applicationId: item.id,
        checked: item.selected == 0 ? false : true,
      }).then((res) => {
        if (res.code == 0) {
          this.getApplicationList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    pageItem(item) {
      this.selectPageItem = item;
      this.getElementList(item);
      // 获取元素
    },
    selectPage(item) {
      console.log("页面");
      console.log(item);
      let data = {
        roleId: this.groupInfo.id,
        pageId: item.id,
        checked: item.selected == 0 ? false : true,
      };
      setGroupList(data).then((res) => {
        if (res.code == 0) {
          this.getPageList();
        }
      });
    },
    // 多选框选中功能权限操作
    selectAllFunction(item) {
      let data = {
        roleId: this.groupInfo.id,
        functionId: item.id,
        checked: item.selected == 0 ? false : true,
      };
      setGroupFunction(data).then((res) => {
        if (res.code == 0) {
          this.getFunctionList();
        }
      });
    },
    handleClick(tab) {
      let index = tab.index;
      this.activeName = index;
      if (index == 0) this.getPageList();
      if (index == 1) this.getMenusList();
      if (index == 2) this.getFunctionList();
    },
    getVersion(item) {
      // 版本
      resourceVersion({ applicationId: item.id }).then((res) => {
        if (res.code == 0) {
          this.versionOption = res.data.list;
          this.version = res.data.list[0];
          if (this.activeName == 0) this.getPageList();
          if (this.activeName == 1) this.getMenusList();
          if (this.activeName == 2) this.getFunctionList();
        }
      });
    },
    changVersion() {
      if (this.activeName == 0) {
        this.getPageList();
        this.elementList = []
        this.selectPageItem = {}
      }
      if (this.activeName == 1) this.getMenusList();
      if (this.activeName == 2) this.getFunctionList();
    },
    // 应用权限列表
    getApplicationList() {
      applicationPermissionList({
        roleId: this.groupInfo.id,
        version: this.version,
      }).then((res) => {
        console.log(res);
        this.applicationList = res.data.list;
      });
    },
    // 功能权限列表
    getFunctionList() {
      let data = {
        roleId: this.groupInfo.id,
        applicationId: this.selectApp.id,
        version: this.version,
      };
      groupFunction(data).then((res) => {
        if (res.code == 0) {
          this.functionList = res.data.list;
        }
      });
    },
    findAllSelect(arr) {
      arr.forEach((item) => {
        if (item.selected == 1) {
          this.check.push(item.id);
        }
        if (item.children && item.children.length > 0) {
          if (item.selected == 1) {
            this.check.push(item.id);
          }
          this.findAllSelect(item.children);
        }
      });
      return this.check;
    },
    getElementList(item) {
      console.log(item);
      let data = {
        roleId: this.groupInfo.id,
        pageId: item.id,
        version: this.version,
      };
      pageElementPermission(data).then((res) => {
        if (res.code == 0) {
          let data = res.data.list;
          this.check = [];
          let selected = this.findAllSelect(data);
          let newArr = selected.reduce((pre, cur) => {
            if (!pre.includes(cur)) {
              return pre.concat(cur);
            } else {
              return pre;
            }
          }, []);
          this.$refs.elementTree.setCheckedKeys(newArr);
          this.elementList = data;
        }
      });
    },
    getMenusList() {
      let data = {
        roleId: this.groupInfo.id,
        applicationId: this.selectApp.id,
        version: this.version,
      };
      menusList(data).then((res) => {
        if (res.code == 0) {
          this.menuList = res.data.list;
          this.check = [];
          let selected = this.findAllSelect(res.data.list);
          let newArr = selected.reduce((pre, cur) => {
            if (!pre.includes(cur)) {
              return pre.concat(cur);
            } else {
              return pre;
            }
          }, []);
          this.$refs.menusTree.setCheckedKeys(newArr);
        }
      });
    },
    handleMenusCheck(node, selected) {
      // let selected = this.$refs.menusTree.getCheckedNodes();
      console.log(node);
      let data = {
        roleId: this.groupInfo.id,
        menuId: node.id,
        checked: node.selected == 0?true:false,
      };
      this.$nextTick(() => {
        setMenus(data).then((res) => {
          if (res.code == 0) {
            this.check = [];
            this.getMenusList();
          }else{
            this.$message.error(res.message)
          }
        });
      });
    },
    handleElementCheck(node, selected) {
      console.log(node);
      let data = {
        roleId: this.groupInfo.id,
        elementId: node.id,
        checked: selected,
      };
      console.log("设置元素", data);
      this.$nextTick(() => {
        setPageElementPermission(data).then((res) => {
          if (res.code == 0) {
            this.check = [];
            this.getElementList(this.selectPageItem);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.no-data {
  color: rgb(144, 147, 153);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.permissions-page {
  padding: 0 20px 20px 20px;
  .permissions-content {
    margin-top: 10px;
    height: calc(100vh - 200px);
    border-color: rgba(220, 223, 230, 1);
    // overflow-y: auto;
    .right-version {
      position: absolute;
      right: 10px;
      top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // .add-icon {
      //   display: flex;
      //   align-items: center;
      //   margin-right: 5px;
      .add-name {
        font-size: 13px;
        margin-right: 5px;
      }
      //   .add-icon {
      //     border: 1px solid #ccc;
      //     background-color: #fff;
      //     padding: 5px 5px;
      //     border-radius: 5px;
      //     cursor: pointer;
      //   }
      // }
    }
    .content {
      height: calc(100vh - 275px);
    }
    /deep/ .el-checkbox-group {
      padding: 0px;
      display: flex;
      flex-direction: column;
      width: 100%;
      .el-checkbox {
        padding: 20px;
        border-bottom: 1px solid #ccc;
        margin: 0;
      }
    }
    .application {
      border-bottom: 1px solid #ccc;
      .clickItem {
        padding: 20px;
      }
    }
    /deep/ .el-card__body {
      padding: 0;
    }
  }
  /deep/ .el-tabs__nav-scroll {
    justify-content: flex-start;
  }
}
</style>
